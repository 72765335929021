import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['hiddenField']
  static values = {
    storageKey: String,
    versionId: String
  }

  SPECIAL_KEYS = [
    'Shift',
    'Control',
    'Alt',
    'Meta',
    'CapsLock',
    'Tab',
    'Escape',
    'ArrowLeft',
    'ArrowRight',
    'ArrowUp',
    'ArrowDown',
    'Insert',
    'Home',
    'End',
    'PageUp',
    'PageDown',
    'NumLock',
    'ScrollLock',
    'Pause',
    'PrintScreen',
    'ContextMenu',
    'F1',
    'F2',
    'F3',
    'F4',
    'F5',
    'F6',
    'F7',
    'F8',
    'F9',
    'F10',
    'F11',
    'F12'
  ]

  connect() {
    this.keystrokes = this.loadSavedKeystrokes()
    if (this.keystrokes?.versionId != this.versionIdValue) {
      this.clearAllKeystrokesData()
    }
    this.updateHiddenField()
    document.addEventListener('input', this.recordKeystroke)
  }

  disconnect() {
    document.removeEventListener('input', this.recordKeystroke)
  }

  recordKeystroke = event => {
    if (this.SPECIAL_KEYS.includes(event.data)) return

    const caretPosition = getCaretPosition(event.target)
    const keystroke = {
      key: event.data,
      kind: event.inputType,
      timestamp: new Date().getTime(),
      ...caretPosition
    }

    this.keystrokes.push(keystroke)

    this.updateHiddenField()
    this.saveKeystrokes() // Sauvegarde immédiate après chaque frappe
  }

  updateHiddenField() {
    if (this.hasHiddenFieldTarget) {
      this.hiddenFieldTarget.value = JSON.stringify(this.keystrokes)
    }
  }

  saveKeystrokes() {
    if (this.storageKeyValue) {
      localStorage.setItem(
        this.storageKeyValue,
        JSON.stringify({
          versionId: this.versionIdValue,
          keystrokes: this.keystrokes
        })
      )
    }
  }

  loadSavedKeystrokes() {
    if (!this.storageKeyValue) return []

    const savedData = localStorage.getItem(this.storageKeyValue)
    if (savedData) {
      const parsedData = JSON.parse(savedData)
      if (parsedData.versionId === this.versionIdValue) {
        return parsedData.keystrokes
      }
    }
    return []
  }

  clearAllKeystrokesData() {
    const keystrokesPattern = /^writing-\d+-keystrokes$/
    const currentVersionKey = `writing-${this.versionIdValue}-keystrokes`

    Object.keys(localStorage).forEach(key => {
      if (keystrokesPattern.test(key) && key !== currentVersionKey) {
        localStorage.removeItem(key)
      }
    })
  }
}

function getCaretPosition(contentEditableElement) {
  let selection = window.getSelection()
  if (selection.rangeCount === 0) return null

  let range = selection.getRangeAt(0)
  let container = range.commonAncestorContainer

  // Check if the container is a text node, and if so, get its parent element
  if (container.nodeType === Node.TEXT_NODE) {
    container = container.parentNode
  }

  // Now check if the container is inside the contentEditable element
  if (container.closest('[contenteditable="true"]') === contentEditableElement) {
    // Get caret position relative to the parent node
    let startOffset = range.startOffset
    let endOffset = range.endOffset

    return {
      start: startOffset,
      end: endOffset
    }
  }
  return null // Selection is not inside the contenteditable element
}
