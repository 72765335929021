import { Controller } from '@hotwired/stimulus'
import { Sortable } from '@shopify/draggable'
import { SortAnimation } from './sort_animation'
import { ExcludedClasses } from './excluded_classes'
import { useMutation } from 'stimulus-use'

export default class extends Controller {
  static targets = [
    'sentence',
    'composable',
    'placeholder',
    'trash',
    'chunksInput',
    'word',
    'textZoneTemplate',
    'lineBreakTemplate',
    'editable',
    'submit',
    'textToSpeechContent'
  ]

  editableClasses = ['shadow', 'border', 'border-gray-lighter', 'bg-white', 'px-4', 'py-3']

  connect() {
    this.#initDraggable()
    this.#initEditableZones()
    useMutation(this, { childList: true, element: this.sentenceTarget })
    this.#updateTurboForm(this.sentenceTarget)
    this.#capitalizeStartsOfSentences()
  }

  // actions

  focus(e) {
    // this.#initEditableZones()
    if (this.chunksInputTarget.value) return

    this.editableTarget.focus()
  }

  // ------------------------------------
  filterKeypress(e) {
    if (e.key == 'Enter') e.preventDefault()
    if (this.isEnterKey(e)) {
      this.handleEnterKeyPress(e)
    } else if (this.isBackspaceKey(e)) {
      this.handleBackspaceKeyPress(e)
    }
  }

  isEnterKey(e) {
    return e.key == 'Enter'
  }

  isBackspaceKey(e) {
    return e.key == 'Backspace'
  }

  handleEnterKeyPress(e) {
    if (this.#isTheCursorPosAtTheEndOfString(e.currentTarget)) {
      this.addNewEditableZoneAfter(e.currentTarget)
    } else {
      this.#splitEditableZone(e)
    }
    this.#capitalizeStartsOfSentences()
    this.#buildParamsFromSentence()
  }

  addNewEditableZoneAfter(editableZone) {
    if (editableZone.textContent.length > 0) {
      if (!editableZone.nextElementSibling) {
        editableZone.after(this.#template)
      }

      if (
        editableZone.previousElementSibling &&
        editableZone.previousElementSibling.textContent.length > 0
      ) {
        editableZone.before(this.#template)
      }
      const newEditableZone = editableZone.nextElementSibling
      newEditableZone.focus()
      newEditableZone.classList.add(...this.editableClasses)
      newEditableZone.before(this.#template)
    } else {
      editableZone.after(this.#linebreakTemplate)
      editableZone.classList.remove(...this.editableClasses)
      let newStart = editableZone.nextElementSibling
      newStart.after(this.#template)
      let nextContentEditable = newStart.nextElementSibling
      nextContentEditable.focus()
    }
    this.#capitalizeStartsOfSentences()
  }

  handleBackspaceKeyPress(e) {
    let previousElement = e.currentTarget.previousElementSibling

    if (previousElement && previousElement.classList.contains('linebreak')) {
      this.#setEndOfContenteditable(previousElement.previousElementSibling)
      previousElement.remove()
      e.currentTarget.remove()
    } else if (previousElement && previousElement.classList.contains('user-composed')) {
      this.#setEndOfContenteditable(previousElement)
      previousElement.focus()
      e.currentTarget.remove()
    } else if (previousElement && previousElement.classList.contains('composed-word')) {
      this.#setEndOfContenteditable(previousElement.previousElementSibling)
    } else {
      if (e.currentTarget.textContent.length == 0) {
        this.#setEndOfContenteditable(previousElement)
        e.currentTarget.remove()
      }
    }
    this.#buildParamsFromSentence()
  }

  mutate(entries) {
    entries.forEach(entry => {
      this.#updateTurboForm(entry.target)
    })
  }

  updateFreeTextZone(e) {
    const freeTextZone = e.currentTarget
    if (freeTextZone.textContent.length > 0) {
      freeTextZone.classList.add('word', 'composed-word', 'user-composed')
      freeTextZone.classList.remove('editable')
      e.currentTarget.classList.add(...this.editableClasses)
      this.#addEditableZoneAfter(freeTextZone)
      this.#addEditableZoneBefore(freeTextZone)
    } else {
      freeTextZone.classList.remove('word', 'composed-word', 'user-composed')
      freeTextZone.classList.add('editable')
    }
    this.#buildParamsFromSentence()
  }

  // private

  #capitalizeRegex = /^[A-Za-zÀ-ÿ]/

  #capitalize(element) {
    if (!element) return
    element =
      element.querySelector('.raw_content') ||
      element.querySelector('[data-primer--text-to-speech--component-target="content"]') ||
      element.querySelector('[data-primer--text-to-speech--component-target="icon content"]') ||
      element
    element.dataset.original ||= element.textContent
    element.textContent = element.textContent
      .trimStart()
      .replace(this.#capitalizeRegex, c => c.toUpperCase())
  }

  #uncapitalize(element) {
    if (!element) return

    element =
      element.querySelector('.raw_content') ||
      element.querySelector('[data-primer--text-to-speech--component-target="content"]') ||
      element.querySelector('[data-primer--text-to-speech--component-target="icon content"]') ||
      element
    if (element.dataset.original) {
      element.textContent = element.dataset.original
    }
  }

  #addEditableZoneBefore(element) {
    if (
      !element.previousElementSibling ||
      !element.previousElementSibling.classList.contains('editable')
    ) {
      element.before(this.#template)
    }
  }

  #addEditableZoneAfter(element) {
    if (!element.nextElementSibling || !element.nextElementSibling.classList.contains('editable')) {
      element.after(this.#template)
    }
  }

  #initDraggable() {
    const containers = [this.composableTarget, this.sentenceTarget, this.trashTarget]
    const draggable = new Sortable(containers, {
      draggable: '.word',
      sortAnimation: {
        duration: 200,
        easingFunction: 'ease-in-out'
      },
      delay: {
        mouse: 100, // best UX delay founded
        drag: 0,
        touch: 100
      },
      classes: {
        mirror: ['opacity-50', 'z-30', 'draggable-mirror'],
        'source:dragging': ['opacity-50', '!border-green', '!bg-green-light']
      },
      plugins: [ExcludedClasses, SortAnimation]
    })
    draggable.on('drag:start', this.#handleDragStart.bind(this))
    draggable.on('drag:stopped', this.#handleDragStop.bind(this))
    draggable.on('drag:over:container', this.#handleHover.bind(this))
    draggable.on('drag:out:container', this.#handleOut.bind(this))
    this.draggable = draggable
  }

  #initEditableZones() {
    const editables = this.sentenceTarget.querySelectorAll('.editable')
    const composedWords = this.sentenceTarget.querySelectorAll('.composed-word')

    editables.forEach(editable => {
      editable.remove()
    })

    if (composedWords.length > 0) {
      composedWords.forEach(word => {
        this.#buildEditableZones(word)
      })
    } else {
      this.sentenceTarget.appendChild(this.#template)
    }
    this.#capitalizeStartsOfSentences()
  }

  #handleDragStart(event) {
    this.tooltipSibilings.forEach(tooltip => tooltip.disable())
    this.#toggleGrabCursor(true, event.data.source)
  }

  #handleHover(event) {
    this.#magnifyTrash(event.data, 'hover')
    this.#preventRezing() // while we drag an element we prevent the resizing of the zone
  }

  #handleOut(event) {
    this.#magnifyTrash(event.data, 'out')
    this.#enableRezing()
  }

  #handleDragStop(event) {
    this.#composedWordTransform(event.data)
    this.#buildParamsFromSentence()
    this.#initEditableZones()
    this.#togglePlaceholder()
    this.#enableRezing()
    this.tooltipSibilings.forEach(tooltip => tooltip.enable())
    this.#toggleGrabCursor(false, event.data.source)
    this.#uncapitalize(this.#getTheSecondWordOfSentence())
    this.#capitalizeStartsOfSentences()
    this.#buildParamsFromSentence()
  }

  #endSentenceRegex = /[.!?]\s*\n*\r*/g

  #capitalizeStartsOfSentences(element) {
    let array = Array.from(this.sentenceTarget.querySelectorAll('.composed-word'))

    array.forEach((element, index) => {
      if (index > 0 && this.#endSentenceRegex.test(array[index - 1].textContent)) {
        this.#capitalize(element)
      } else if (index == 0) {
        this.#capitalize(element)
      } else {
        this.#uncapitalize(element)
      }
    })
  }

  #getTheFirstWordOfSentence() {
    return this.sentenceTarget.firstElementChild.nextElementSibling
  }

  #getTheSecondWordOfSentence() {
    return this.#getTheFirstWordOfSentence().nextElementSibling.nextElementSibling
  }

  #updateTurboForm(entry) {
    if (Array.from(entry.querySelectorAll('.composed-word')).length == 0) {
      this.submitTarget.dataset.turbo = true
    } else {
      this.submitTarget.dataset.turbo = this.submitTarget.dataset.nextChapter
    }
  }

  #splitEditableZone(e) {
    const editableZone = e.currentTarget
    const editableZoneText = editableZone.textContent
    const cursorPosition = window.getSelection().getRangeAt(0).startOffset

    const firstPart = editableZoneText.slice(0, cursorPosition)
    const secondPart = editableZoneText.slice(cursorPosition, editableZoneText.length)

    editableZone.textContent = firstPart
    editableZone.after(this.#template)
    editableZone.nextElementSibling.textContent = secondPart
    editableZone.nextElementSibling.classList.add(...this.editableClasses)
    editableZone.nextElementSibling.classList.remove('editable')
    editableZone.nextElementSibling.focus()
    this.updateFreeTextZone({ currentTarget: editableZone.nextElementSibling })
  }

  #setEndOfContenteditable(contentEditableElement) {
    let range, selection

    if (document.createRange) {
      //Firefox, Chrome, Opera, Safari, IE 9+
      range = document.createRange() //Create a range (a range is a like the selection but invisible)
      range.selectNodeContents(contentEditableElement) //Select the entire contents of the element with the range
      range.collapse(false) //collapse the range to the end point. false means collapse to end rather than the start
      selection = window.getSelection() //get the selection object (allows you to change selection)
      selection.removeAllRanges() //remove any selections already made
      selection.addRange(range) //make the range you have just created the visible selection
    } else if (document.selection) {
      //IE 8 and lower
      range = document.body.createTextRange() //Create a range (a range is a like the selection but invisible)
      range.moveToElementText(contentEditableElement) //Select the entire contents of the element with the range
      range.collapse(false) //collapse the range to the end point. false means collapse to end rather than the start
      range.select() //Select the range (make it the visible selection
    }
  }

  #isTheCursorPosAtTheEndOfString(target) {
    return window.getSelection().getRangeAt(0).startOffset === target.innerText.length
  }

  #toggleGrabCursor(toggle, element) {
    if (toggle) {
      element.classList.add('cursor-grab')
    } else {
      element.classList.remove('cursor-grab')
    }
  }

  #buildEditableZones(element) {
    if (!element.previousElementSibling) {
      this.sentenceTarget.insertBefore(this.#template, element)
    } else {
      if (element.previousElementSibling.classList.contains('composed-word')) {
        this.sentenceTarget.insertBefore(this.#template, element)
      }
    }
    if (!element.nextElementSibling) {
      element.after(this.#template)
    }
  }

  #preventRezing() {
    const height = this.sentenceTarget.offsetHeight
    this.sentenceTarget.style.height = height + 'px'
  }

  #enableRezing() {
    this.sentenceTarget.style.height = null
  }

  #togglePlaceholder() {
    if (this.sentenceTarget.querySelectorAll('.composed-word').length > 0) {
      this.placeholderTarget.classList.add('hidden')
    } else {
      this.placeholderTarget.classList.remove('hidden')
    }
  }

  #composedWordTransform(data) {
    const element = data.originalSource
    const target = data.originalSource.parentNode
    const source = data.sourceContainer
    if (source.id == 'composable' && target.id == 'sentence') {
      element.classList.add('composed-word')
      target.classList.remove('bg-blue-light')
      target.classList.add('bg-white')
    } else if (source.id == 'sentence' && target.id == 'trash') {
      this.#cloneWordOntrash(element)
      element.classList.add('trash-on-drag')
    } else if (source.id == 'sentence' && target.id == 'composable') {
      element.classList.remove('composed-word')
    } else if (source.id == 'composable' && target.id == 'trash') {
      this.#cloneWordOntrash(element)
      element.classList.add('trash-on-drag')
    }
  }

  #magnifyTrash(datas, state) {
    if (datas.overContainer.id == 'trash') {
      const element = datas.source
      const container = datas.overContainer
      if (state == 'hover') {
        container.querySelector('#trash-icon').classList.add('fill-red', 'h-5')
        container.querySelector('#trash-icon').classList.remove('fill-gray-lighter', 'h-4')
        element.classList.add('draggable-source--hide')
        const mirror = document.querySelector('.draggable-mirror')
        mirror.classList.add('!border-red', 'opacity-30')
      } else if (state == 'out') {
        container.querySelector('#trash-icon').classList.remove('fill-red', 'h-5')
        container.querySelector('#trash-icon').classList.add('fill-gray-lighter', 'h-4')
        element.classList.remove('draggable-source--hide')
        const mirror = document.querySelector('.draggable-mirror')
        mirror.classList.remove('!border-red', 'opacity-30')
      }
    }
  }

  #cloneWordOntrash(element) {
    const clone = element.cloneNode(true)
    const trash = this.trashTarget.querySelector('#trash-icon')
    clone.classList.remove(
      'gu-transit',
      'composed-word',
      'gu-transit-hidden',
      'draggable-source--is-dragging',
      'draggable--over'
    )
    element.remove()
    if (!clone.classList.contains('user-composed')) {
      this.composableTarget.appendChild(clone)
    }
    trash.classList.remove('fill-red', 'h-5')
    trash.classList.add('fill-gray-lighter', 'h-4')
  }

  #buildParamsFromSentence() {
    const chunksParams = [...this.sentenceTarget.querySelectorAll('.composed-word')].map(label => {
      if (label.classList.contains('user-composed')) {
        return { text: label.textContent, type: 'text' }
      } else if (label.classList.contains('linebreak')) {
        return { text: '\n', type: 'linebreak' }
      } else {
        return {
          text: (label.dataset.original || label.textContent).trim(),
          type: 'label',
          original_position: label.dataset.originalPosition
        }
      }
    })
    this.textToSpeechContentTarget.dataset.text = chunksParams.map(el => el.text.trim()).join(' ')
    this.chunksInputTarget.value = JSON.stringify(chunksParams)
  }

  // getters
  get #template() {
    return this.textZoneTemplateTarget.content.cloneNode(true)
  }

  get #linebreakTemplate() {
    return this.lineBreakTemplateTarget.content.cloneNode(true)
  }

  get tooltipSibilings() {
    return this.application.controllers.filter(
      controller => controller.identifier == 'primer--tooltip--component'
    )
  }
}
